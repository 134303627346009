import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  Icon,
  Button,
  // Accordion,
  // Segment,
  Image,
} from "semantic-ui-react";

import { InterfaceMap } from "../Info/InterfaceMap";
import { TourStep } from "./Tour";
import "../../css/helpBar.css";
import glamLogo from "../../img/GLAM2-Logo.png";

import Drawer from "@mui/material/Drawer";

import { Trans } from "@lingui/macro";
import Toolbar from "@mui/material/Toolbar";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";

function signUpForm() {
  return {
    __html:
      '<div class="ctct-inline-form" data-form-id="80158e4e-9cbd-41fd-8762-82ca649dbfe2"></div>',
  };
}

const HelpContent = () => {
  const [activeIndex, setIndex] = useState(0);
  const [loaded, setLoaded] = useState(false);

  const accordionClick = (e, titleProps) => {
    const index = titleProps.index;
    const newIndex = activeIndex === index ? -1 : index;
    setIndex(newIndex);
  };

  return (
    <Accordion>
      <AccordionSummary>
        <Icon name="dropdown" color="blue" />
        <span className="glamBlue">
          <Trans>About</Trans> GLAM
        </span>
      </AccordionSummary>
      <AccordionDetails>
        <p>
          <Trans>
            The Global Agriculture Monitoring System (GLAM) is a web-based
            platform to enable near-real-time monitoring of global croplands,
            primarily using NASA MODIS satellite data. After years of
            operational use and valuable feedback from partners, it was time to
            redesign this system to be faster, more flexible, and to capitalize
            new datasets coming online and new computing architectures
            available.
            <br />
            <br />
            More information on this project's past, present, and future can be
            found{" "}
            <a
              target="_blank"
              rel="noopener"
              href="https://nasaharvest.org/news/new-cloud-based-global-agricultural-monitoring-system-fast-flexible-and-user-friendly"
            >
              here
            </a>
            .
          </Trans>
        </p>
      </AccordionDetails>
      <AccordionSummary>
        <Icon name="dropdown" color="blue" />
        <span className="glamBlue">
          <Trans>FAQs & Resources</Trans>
        </span>
      </AccordionSummary>
      <AccordionDetails>
        <p>
          <Trans>
            For information on the different Products and Crop Masks available
            in the system, please click the yellow 'info' buttons on the
            navigation panel.
          </Trans>
        </p>
        <p>
          <Trans>
            New user guides and other training materials are forthcoming.
          </Trans>
        </p>
        <p>
          <Trans>
            Click below to sign up for our mailing list and receive
            announcements regarding updates, new features, and trainings.
          </Trans>
        </p>
        <p className="alignCenter">
          <Button
            icon="signup"
            size="tiny"
            color="blue"
            content={<Trans>Sign Up</Trans>}
            href="signup"
            target="_blank"
            rel="noopener"
          />
        </p>
        <p>
          <Trans>
            To provide feedback on the system, report issues, or for other
            assistance - please visit and join the GLAM System Users 'Google
            Group'.
          </Trans>
          <br />
        </p>
        <p className="alignCenter">
          <Button
            icon="group"
            size="tiny"
            color="blue"
            content={`GLAM ${(<Trans>System Users</Trans>)}`}
            href="https://groups.google.com/g/glam-system-users"
            target="_blank"
            rel="noopener"
          />
        </p>
      </AccordionDetails>

      <AccordionSummary>
        <Icon name="dropdown" color="blue" />
        <span className="glamBlue">
          <Trans>National & Regional Interfaces</Trans>
        </span>
      </AccordionSummary>

      <AccordionDetails>
        <InterfaceMap />
        <p className="alignCenter">
          <Trans>
            Click one of the highlighted regions to launch a different
            interface.
          </Trans>
        </p>
      </AccordionDetails>

      <AccordionSummary>
        <Icon name="dropdown" color="blue" />
        <span className="glamBlue">
          <Trans>For Developers</Trans>
        </span>
      </AccordionSummary>
      <AccordionDetails>
        <p>
          <Trans>
            <a target="_blank" rel="noopener" href="">
              Link to API Documentation
            </a>
          </Trans>
        </p>
      </AccordionDetails>
    </Accordion>
  );
};

const HelpBar = () => {
  const [visible, setVisible] = useState(false);
  // const {state, dispatch} = useStore();
  const state = useSelector((state) => state.core);
  const dispatch = useDispatch();

  useEffect(() => {
    if (state.tourState === 12) {
      setVisible(true);
    } else if (state.tourState < 12) {
      setVisible(false);
    }
  }, [state.tourState]);

  const closeDrawer = () => {
    dispatch({ type: "helpDrawer", value: false });
  };

  return (
    <Drawer anchor="right" open={state.helpDrawer} onClose={closeDrawer}>
      <Toolbar />

      <Paper
        elevation={3}
        sx={{
          width: "50vh",
        }}
      >
        {/* <Image href="/" size='medium' centered src={glamLogo}/> */}

        <HelpContent />

        <TourStep
          step={12}
          position="bottom left"
          message={
            <span>
              <Trans>
                Open this panel to view helpful information about the GLAM
                system.
              </Trans>
            </span>
          }
        />
      </Paper>
    </Drawer>
  );
};

export default HelpBar;
